<template>
  <div class="cryptoCarousel">
    <div class="cryptoList">
      <div class="list">
        <div class="cc rowup">
          <div v-for="item of cryptoList" :key="'pre'+item.logo" class="item">
            <img class="top_down_icon_img" :src="item.logo" alt="">
          </div>
          <div v-for="item of cryptoList" :key="'next'+item.logo" class="item">
            <img class="top_down_icon_img" :src="item.logo" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { payMethods } from './img'

export default {
  name: 'CryptoCarousel',
  data() {
    return {
      cryptoList: [],
      sliderIndex: 0
    }
  },
  mounted() {
    this.cryptoList = payMethods
  },
  methods: {
    moveToPrev() {
      if (this.sliderIndex > 0) {
        this.sliderIndex = this.sliderIndex - 1
      } else {
        this.sliderIndex = this.list.length - 1
      }
    },
    moveToNext() {
      if (this.sliderIndex < this.list.length - 1) {
        this.sliderIndex = this.sliderIndex + 1
      } else {
        this.sliderIndex = 0
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .cryptoCarousel {
    margin: auto;
    // background: #fafafa;
    background: #fff;
    font-family: SamsungSharpSans-Bold;
    text-align: center;
    @media (max-width: 960px) {
      width: 100%;
      margin-top: 40px;
    }
    h3 {
      font-weight: 700;
      font-size: 60px;
      margin: 100px 0;
      @media (max-width: 960px) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 48px;
      }
    }
    .cryptoList {
      background: #fff;
      background: #fafafa;
      background: #fafafa;
      padding: 80px 0;
    }
    .list {
      width: 1280px;
      margin: auto;
      position: relative;
      overflow: hidden;
      @media (max-width: 960px) {
        width: 90%;
        padding: 0 5%;
      }
    }

    .list .rowup {
      display: flex;
      -webkit-animation: 20s rowup linear infinite normal;
      animation: 20s rowup linear infinite normal;
      position: relative;
      .item {
        .top_down_icon_img {
        //   width: 64px;
          height: 38px;
          margin-right: 120px;
        }
      }
    }
    @keyframes rowup {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      100% {
        -webkit-transform: translate3d(-640px, 0, 0);
        transform: translate3d(-1280px, 0, 0);
      }
    }
    .sliderBannerContainer {
      display: flex;
      width: 1280px;
      margin: auto;
      .nextBtn {
        display: flex;
        align-items: center;
        img {
          width: 80px;
          cursor: pointer;
        }
      }
      .slider_b {
        overflow: visible;
        height: 320px;
        flex: 1;
        .sliderContainer {
          width: 1040px;
          margin: auto;
          display: flex;
          .sliderList {
            width: 21%;
            margin: 0 2%;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            padding: 40px;
            transition: All 0.4s ease-in-out;
            -webkit-transition: All 0.4s ease-in-out;
            -moz-transition: All 0.4s ease-in-out;
            -o-transition: All 0.4s ease-in-out;
            .sliderIcon {
              width: 100px;
              margin-bottom: 30px;
            }
            .slideTitle {
              font-weight: 600;
              font-size: 30px;
              margin-bottom: 20px;
            }
            .sliderTips {
              font-size: 14px;
              color: #999999;
            }
          }
          .sliderList:hover {
            transform: translate(0, -20px);
            -webkit-transform: translate(0, -20px);
            -moz-transform: translate(0, -20px);
            -o-transform: translate(0, -20px);
            -ms-transform: translate(0, -20px);
          }
        }
      }
    }
  }
  </style>
