import opencart from '@/assets/images/pay/opencart.png'
import opencart2 from '@/assets/images/pay/opencart2.png'
import commerce from '@/assets/images/pay/commerce.png'
import shopify from '@/assets/images/pay/shopify.png'
import shoplazza1 from '@/assets/images/pay/shoplazza1.png'
import shopyy from '@/assets/images/pay/shopyy.png'
import ueeshop from '@/assets/images/pay/ueeshop.png'
import wordpress from '@/assets/images/pay/wordpress.png'
import zencart from '@/assets/images/pay/zencart.png'
import magento from '@/assets/images/pay/magento.png'

// import googleplay from '@/assets/images/pay/googleplay.png';
// import appstore from '@/assets/images/pay/appstore.jpeg';
import prestashop from '@/assets/images/pay/prestashop.png'
import shopline from '@/assets/images/pay/shopline.jpg'

export const payMethods = [
  {
    label: 'opencart',
    logo: opencart
  },

  {
    label: 'commerce',
    logo: commerce
  },
  {
    label: 'prestashop',
    logo: prestashop
  },
  {
    label: 'shopify',
    logo: shopify
  },
  {
    label: 'shopline',
    logo: shopline
  },
  {
    label: 'opencart2',
    logo: opencart2
  },
  // {
  //   label: 'googleplay',
  //   logo: googleplay,
  // },
  // {
  //   label: 'appstore',
  //   logo: appstore,
  // },
  {
    label: 'magento',
    logo: magento
  },
  {
    label: 'zencart',
    logo: zencart
  },
  {
    label: 'shoplazza1',
    logo: shoplazza1
  },
  {
    label: 'shopyy',
    logo: shopyy
  },
  {
    label: 'ueeshop',
    logo: ueeshop
  },
  {
    label: 'wordpress',
    logo: wordpress
  }
]

export const foo = ''
