import AL from '@/assets/images/flag/AL.png'
import DZ from '@/assets/images/flag/DZ.png'
import AO from '@/assets/images/flag/AO.png'
import AR from '@/assets/images/flag/AR.png'
import AM from '@/assets/images/flag/AM.png'
import AU from '@/assets/images/flag/AU.png'
import AT from '@/assets/images/flag/AT.png'
import AZ from '@/assets/images/flag/AZ.png'
import BD from '@/assets/images/flag/BD.png'
import BY from '@/assets/images/flag/BY.png'
import BT from '@/assets/images/flag/BT.png'
import BA from '@/assets/images/flag/BA.png'
import BW from '@/assets/images/flag/BW.png'
import BR from '@/assets/images/flag/BR.png'
import BN from '@/assets/images/flag/BN.png'
import BG from '@/assets/images/flag/BG.png'
import BF from '@/assets/images/flag/BF.png'
import BI from '@/assets/images/flag/BI.png'
import CV from '@/assets/images/flag/CV.png'
import KH from '@/assets/images/flag/KH.png'
import CM from '@/assets/images/flag/CM.png'
import CA from '@/assets/images/flag/CA.png'
import KY from '@/assets/images/flag/KY.png'
import CL from '@/assets/images/flag/CL.png'
import CX from '@/assets/images/flag/CX.png'
import CO from '@/assets/images/flag/CO.png'
import KM from '@/assets/images/flag/KM.png'
import CG from '@/assets/images/flag/CG.png'
import CK from '@/assets/images/flag/CK.png'
import CR from '@/assets/images/flag/CR.png'
import HR from '@/assets/images/flag/HR.png'
import CW from '@/assets/images/flag/CW.png'
import CY from '@/assets/images/flag/CY.png'
import CZ from '@/assets/images/flag/CZ.png'
import CI from '@/assets/images/flag/CI.png'
import CD from '@/assets/images/flag/CD.png'
import DK from '@/assets/images/flag/DK.png'
import DJ from '@/assets/images/flag/DJ.png'
import DM from '@/assets/images/flag/DM.png'
import DO from '@/assets/images/flag/DO.png'
import EC from '@/assets/images/flag/EC.png'
import EG from '@/assets/images/flag/EG.png'
import SV from '@/assets/images/flag/SV.png'
import GQ from '@/assets/images/flag/GQ.png'
import ER from '@/assets/images/flag/ER.png'
import EE from '@/assets/images/flag/EE.png'
import SZ from '@/assets/images/flag/SZ.png'
import ET from '@/assets/images/flag/ET.png'
import FJ from '@/assets/images/flag/FJ.png'
import FI from '@/assets/images/flag/FI.png'
import FR from '@/assets/images/flag/FR.svg'
import GA from '@/assets/images/flag/GA.png'
import GM from '@/assets/images/flag/GM.png'
import GE from '@/assets/images/flag/GE.png'
import DE from '@/assets/images/flag/DE.svg'
import GH from '@/assets/images/flag/GH.png'
import GI from '@/assets/images/flag/GI.png'
import GR from '@/assets/images/flag/GR.png'
import GL from '@/assets/images/flag/GL.png'
import GD from '@/assets/images/flag/GD.png'
import GP from '@/assets/images/flag/GP.png'
import GU from '@/assets/images/flag/GU.png'
import GT from '@/assets/images/flag/GT.png'
import GG from '@/assets/images/flag/GG.png'
import GN from '@/assets/images/flag/GN.png'
import GW from '@/assets/images/flag/GW.png'
import GY from '@/assets/images/flag/GY.png'
import HT from '@/assets/images/flag/HT.png'
import VA from '@/assets/images/flag/VA.png'
import HN from '@/assets/images/flag/HN.png'
import HK from '@/assets/images/flag/HK.png'
import HU from '@/assets/images/flag/HU.png'
import IS from '@/assets/images/flag/IS.png'
import IN from '@/assets/images/flag/IN.png'
import ID from '@/assets/images/flag/ID.svg'
import IE from '@/assets/images/flag/IE.png'
import IM from '@/assets/images/flag/IM.png'
import IL from '@/assets/images/flag/IL.png'
import IT from '@/assets/images/flag/IT.svg'
import JM from '@/assets/images/flag/JM.png'
import JP from '@/assets/images/flag/JP.svg'
import JE from '@/assets/images/flag/JE.png'
import JO from '@/assets/images/flag/JO.png'
import KZ from '@/assets/images/flag/KZ.png'
import KE from '@/assets/images/flag/KE.png'
import KI from '@/assets/images/flag/KI.png'
import KR from '@/assets/images/flag/KR.png'
import KW from '@/assets/images/flag/KW.png'
import KG from '@/assets/images/flag/KG.png'
import LA from '@/assets/images/flag/LA.png'
import LV from '@/assets/images/flag/LV.png'
import LS from '@/assets/images/flag/LS.png'
import LR from '@/assets/images/flag/LR.png'
import LI from '@/assets/images/flag/LI.png'
import LT from '@/assets/images/flag/LT.png'
import LU from '@/assets/images/flag/LU.png'
import MO from '@/assets/images/flag/MO.png'
import MG from '@/assets/images/flag/MG.png'
import MW from '@/assets/images/flag/MW.png'
import MY from '@/assets/images/flag/MY.png'
import MV from '@/assets/images/flag/MV.png'
import ML from '@/assets/images/flag/ML.png'
import MT from '@/assets/images/flag/MT.png'
import MQ from '@/assets/images/flag/MQ.png'
import MR from '@/assets/images/flag/MR.png'
import MU from '@/assets/images/flag/MU.png'
import YT from '@/assets/images/flag/YT.png'
import MX from '@/assets/images/flag/MX.png'
import MC from '@/assets/images/flag/MC.png'
import MN from '@/assets/images/flag/MN.png'
import ME from '@/assets/images/flag/ME.png'
import MS from '@/assets/images/flag/MS.png'
import MA from '@/assets/images/flag/MA.png'
import MZ from '@/assets/images/flag/MZ.png'
import NA from '@/assets/images/flag/NA.png'
import NR from '@/assets/images/flag/NR.png'
import NP from '@/assets/images/flag/NP.png'
import NL from '@/assets/images/flag/NL.png'
import NC from '@/assets/images/flag/NC.png'
import NZ from '@/assets/images/flag/NZ.png'
import NE from '@/assets/images/flag/NE.png'
import NG from '@/assets/images/flag/NG.png'
import NU from '@/assets/images/flag/NU.png'
import MK from '@/assets/images/flag/MK.png'
import NO from '@/assets/images/flag/NO.png'
import OM from '@/assets/images/flag/OM.png'
import PW from '@/assets/images/flag/PW.png'
import PA from '@/assets/images/flag/PA.png'
import PG from '@/assets/images/flag/PG.png'
import PY from '@/assets/images/flag/PY.png'
import PE from '@/assets/images/flag/PE.png'
import PH from '@/assets/images/flag/PH.png'
import PN from '@/assets/images/flag/PN.png'
import PL from '@/assets/images/flag/PL.svg'
import PT from '@/assets/images/flag/PT.png'
import PR from '@/assets/images/flag/PR.png'
import QA from '@/assets/images/flag/QA.png'
import MD from '@/assets/images/flag/MD.png'
import RO from '@/assets/images/flag/RO.png'
import RW from '@/assets/images/flag/RW.png'
import RE from '@/assets/images/flag/RE.png'
import BL from '@/assets/images/flag/BL.png'
import KN from '@/assets/images/flag/KN.png'
import LC from '@/assets/images/flag/LC.png'
import MF from '@/assets/images/flag/MF.png'
import PM from '@/assets/images/flag/PM.png'
import VC from '@/assets/images/flag/VC.png'
import WS from '@/assets/images/flag/WS.png'
import SM from '@/assets/images/flag/SM.png'
import ST from '@/assets/images/flag/ST.png'
import SA from '@/assets/images/flag/SA.png'
import SN from '@/assets/images/flag/SN.png'
import RS from '@/assets/images/flag/RS.png'
import SC from '@/assets/images/flag/SC.png'
import SL from '@/assets/images/flag/SL.png'
import SG from '@/assets/images/flag/SG.png'
import SK from '@/assets/images/flag/SK.png'
import SI from '@/assets/images/flag/SI.png'
import ZA from '@/assets/images/flag/ZA.png'
import ES from '@/assets/images/flag/ES.png'
import LK from '@/assets/images/flag/LK.png'
import SR from '@/assets/images/flag/SR.png'
import SJ from '@/assets/images/flag/SJ.png'
import SE from '@/assets/images/flag/SE.png'
import CH from '@/assets/images/flag/CH.svg'
import SY from '@/assets/images/flag/SY.png'
import TW from '@/assets/images/flag/TW.svg'
import TJ from '@/assets/images/flag/TJ.png'
import TH from '@/assets/images/flag/TH.svg'
import TL from '@/assets/images/flag/TL.png'
import TG from '@/assets/images/flag/TG.png'
import TK from '@/assets/images/flag/TK.png'
import TO from '@/assets/images/flag/TO.png'
import TT from '@/assets/images/flag/TT.png'
import TN from '@/assets/images/flag/TN.png'
import TR from '@/assets/images/flag/TR.png'
import TM from '@/assets/images/flag/TM.png'
import TC from '@/assets/images/flag/TC.png'
import TV from '@/assets/images/flag/TV.png'
import UG from '@/assets/images/flag/UG.png'
import UA from '@/assets/images/flag/UA.svg'
import AE from '@/assets/images/flag/AE.svg'
import GB from '@/assets/images/flag/GB.svg'
import TZ from '@/assets/images/flag/TZ.png'
import US from '@/assets/images/flag/US.png'
import UY from '@/assets/images/flag/UY.png'
import UZ from '@/assets/images/flag/UZ.png'
import VU from '@/assets/images/flag/VU.png'
import VE from '@/assets/images/flag/VE.png'
import VN from '@/assets/images/flag/VN.svg'
import VI from '@/assets/images/flag/VI.png'
import WF from '@/assets/images/flag/WF.png'
import EH from '@/assets/images/flag/EH.png'
import ZM from '@/assets/images/flag/ZM.png'
import BM from '@/assets/images/flag/BM.png'
import BJ from '@/assets/images/flag/BJ.svg'
import BZ from '@/assets/images/flag/BZ.png'
import BE from '@/assets/images/flag/BE.png'
import BB from '@/assets/images/flag/BB.png'
import BH from '@/assets/images/flag/BH.png'
import BS from '@/assets/images/flag/BS.png'
import AW from '@/assets/images/flag/AW.png'
import AI from '@/assets/images/flag/AI.png'
import AD from '@/assets/images/flag/AD.png'
import AX from '@/assets/images/flag/AX.png'

export const name = 'flags'
export const flags = [
  {
    name: 'Albania',
    zhCommonName: '阿尔巴尼亚',
    enCommonName: 'Albania',
    logo: AL,
    value: 1,
    selected: true
  },
  {
    name: 'Algeria',
    zhCommonName: '阿尔及利亚',
    enCommonName: 'Algeria',
    logo: DZ,
    value: 1,
    selected: true
  },
  {
    name: 'Andorra',
    zhCommonName: '安道尔',
    enCommonName: 'Andorra',
    logo: AD,
    value: 1,
    selected: true
  },
  {
    name: 'Angola',
    zhCommonName: '安哥拉',
    enCommonName: 'Angola',
    logo: AO,
    value: 1,
    selected: true
  },
  {
    name: 'Anguilla',
    zhCommonName: '安圭拉',
    enCommonName: 'Anguilla',
    logo: AI,
    value: 1,
    selected: true
  },
  {
    name: 'Argentina',
    zhCommonName: '阿根廷',
    enCommonName: 'Argentina',
    logo: AR,
    value: 1,
    selected: true
  },
  {
    name: 'Armenia',
    zhCommonName: '亚美尼亚',
    enCommonName: 'Armenia',
    logo: AM,
    value: 1,
    selected: true
  },
  {
    name: 'Aruba',
    zhCommonName: '阿鲁巴',
    enCommonName: 'Aruba',
    logo: AW,
    value: 1,
    selected: true
  },
  {
    name: 'Australia',
    zhCommonName: '澳大利亚',
    enCommonName: 'Australia',
    logo: AU,
    value: 1,
    selected: true
  },
  {
    name: 'Austria',
    zhCommonName: '奥地利',
    enCommonName: 'Austria',
    logo: AT,
    value: 1,
    selected: true
  },
  {
    name: 'Azerbaijan',
    zhCommonName: '阿塞拜疆',
    enCommonName: 'Azerbaijan',
    logo: AZ,
    value: 1,
    selected: true
  },
  {
    name: 'Bahamas',
    zhCommonName: '巴哈马',
    enCommonName: 'Bahamas',
    logo: BS,
    value: 1,
    selected: true
  },
  {
    name: 'Bahrain',
    zhCommonName: '巴林',
    enCommonName: 'Bahrain',
    logo: BH,
    value: 1,
    selected: true
  },
  {
    name: 'Bangladesh',
    zhCommonName: '孟加拉国',
    enCommonName: 'Bangladesh',
    logo: BD,
    value: 1,
    selected: true
  },
  {
    name: 'Barbados',
    zhCommonName: '巴巴多斯',
    enCommonName: 'Barbados',
    logo: BB,
    value: 1,
    selected: true
  },
  {
    name: 'Belarus',
    zhCommonName: '白俄罗斯',
    enCommonName: 'Belarus',
    logo: BY,
    value: 1,
    selected: true
  },
  {
    name: 'Belgium',
    zhCommonName: '比利时',
    enCommonName: 'Belgium',
    logo: BE,
    value: 1,
    selected: true
  },
  {
    name: 'Belize',
    zhCommonName: '伯利兹',
    enCommonName: 'Belize',
    logo: BZ,
    value: 1,
    selected: true
  },
  {
    name: 'Benin',
    zhCommonName: '贝宁',
    enCommonName: 'Benin',
    logo: BJ,
    value: 1,
    selected: true
  },
  {
    name: 'Bermuda',
    zhCommonName: '百慕大',
    enCommonName: 'Bermuda',
    logo: BM,
    value: 1,
    selected: true
  },
  {
    name: 'Bhutan',
    zhCommonName: '不丹',
    enCommonName: 'Bhutan',
    logo: BT,
    value: 1,
    selected: true
  },
  {
    name: 'Bosnia and Herzegovina',
    zhCommonName: '波黑',
    enCommonName: 'Bosnia and Herzegovina',
    logo: BA,
    value: 1,
    selected: true
  },
  {
    name: 'Botswana',
    zhCommonName: '博茨瓦纳',
    enCommonName: 'Botswana',
    logo: BW,
    value: 1,
    selected: true
  },
  {
    name: 'Brazil',
    zhCommonName: '巴西',
    enCommonName: 'Brazil',
    logo: BR,
    value: 1,
    selected: true
  },
  {
    name: 'Brunei Darussalam',
    zhCommonName: '文莱',
    enCommonName: 'Brunei Darussalam',
    logo: BN,
    value: 1,
    selected: true
  },
  {
    name: 'Bulgaria',
    zhCommonName: '保加利亚',
    enCommonName: 'Bulgaria',
    logo: BG,
    value: 1,
    selected: true
  },
  {
    name: 'Burkina Faso',
    zhCommonName: '布基纳法索',
    enCommonName: 'Burkina Faso',
    logo: BF,
    value: 1,
    selected: true
  },
  {
    name: 'Burundi',
    zhCommonName: '布隆迪',
    enCommonName: 'Burundi',
    logo: BI,
    value: 1,
    selected: true
  },
  {
    name: 'Cabo Verde',
    zhCommonName: '佛得角',
    enCommonName: 'Cabo Verde',
    logo: CV,
    value: 1,
    selected: true
  },
  {
    name: 'Cambodia',
    zhCommonName: '柬埔寨',
    enCommonName: 'Cambodia',
    logo: KH,
    value: 1,
    selected: true
  },
  {
    name: 'Cameroon',
    zhCommonName: '喀麦隆',
    enCommonName: 'Cameroon',
    logo: CM,
    value: 1,
    selected: true
  },
  {
    name: 'Canada',
    zhCommonName: '加拿大',
    enCommonName: 'Canada',
    logo: CA,
    value: 1,
    selected: true
  },
  {
    name: 'Cayman Islands',
    zhCommonName: '开曼群岛',
    enCommonName: 'Cayman Islands',
    logo: KY,
    value: 1,
    selected: true
  },
  {
    name: 'Chile',
    zhCommonName: '智利',
    enCommonName: 'Chile',
    logo: CL,
    value: 1,
    selected: true
  },
  {
    name: 'Christmas Island',
    zhCommonName: '圣诞岛',
    enCommonName: 'Christmas Island',
    logo: CX,
    value: 1,
    selected: true
  },
  {
    name: 'Colombia',
    zhCommonName: '哥伦比亚',
    enCommonName: 'Colombia',
    logo: CO,
    value: 1,
    selected: true
  },
  {
    name: 'Comoros',
    zhCommonName: '科摩罗',
    enCommonName: 'Comoros',
    logo: KM,
    value: 1,
    selected: true
  },
  {
    name: 'Congo',
    zhCommonName: '刚果共和国',
    enCommonName: 'Congo',
    logo: CG,
    value: 1,
    selected: true
  },
  {
    name: 'Cook Islands',
    zhCommonName: '库克群岛',
    enCommonName: 'Cook Islands',
    logo: CK,
    value: 1,
    selected: true
  },
  {
    name: 'Costa Rica',
    zhCommonName: '哥斯达黎加',
    enCommonName: 'Costa Rica',
    logo: CR,
    value: 1,
    selected: true
  },
  {
    name: 'Croatia',
    zhCommonName: '克罗地亚',
    enCommonName: 'Croatia',
    logo: HR,
    value: 1,
    selected: true
  },
  {
    name: 'Curaçao',
    zhCommonName: '库拉索',
    enCommonName: 'Curaçao',
    logo: CW,
    value: 1,
    selected: true
  },
  {
    name: 'Cyprus',
    zhCommonName: '塞浦路斯',
    enCommonName: 'Cyprus',
    logo: CY,
    value: 1,
    selected: true
  },
  {
    name: 'Czechia',
    zhCommonName: '捷克',
    enCommonName: 'Czechia',
    logo: CZ,
    value: 1,
    selected: true
  },
  {
    name: "Côte d'Ivoire",
    zhCommonName: '科特迪瓦',
    enCommonName: "Côte d'Ivoire",
    logo: CI,
    value: 1,
    selected: true
  },
  {
    name: 'Democratic Republic of the Congo',
    zhCommonName: '刚果民主共和国',
    enCommonName: 'Democratic Republic of the Congo',
    logo: CD,
    value: 1,
    selected: true
  },
  {
    name: 'Denmark',
    zhCommonName: '丹麦',
    enCommonName: 'Denmark',
    logo: DK,
    value: 1,
    selected: true
  },
  {
    name: 'Djibouti',
    zhCommonName: '吉布提',
    enCommonName: 'Djibouti',
    logo: DJ,
    value: 1,
    selected: true
  },
  {
    name: 'Dominica',
    zhCommonName: '多米尼克',
    enCommonName: 'Dominica',
    logo: DM,
    value: 1,
    selected: true
  },
  {
    name: 'Dominican Republic',
    zhCommonName: '多米尼加',
    enCommonName: 'Dominican Republic',
    logo: DO,
    value: 1,
    selected: true
  },
  {
    name: 'Ecuador',
    zhCommonName: '厄瓜多尔',
    enCommonName: 'Ecuador',
    logo: EC,
    value: 1,
    selected: true
  },
  {
    name: 'Egypt',
    zhCommonName: '埃及',
    enCommonName: 'Egypt',
    logo: EG,
    value: 1,
    selected: true
  },
  {
    name: 'El Salvador',
    zhCommonName: '萨尔瓦多',
    enCommonName: 'El Salvador',
    logo: SV,
    value: 1,
    selected: true
  },
  {
    name: 'Equatorial Guinea',
    zhCommonName: '赤道几内亚',
    enCommonName: 'Equatorial Guinea',
    logo: GQ,
    value: 1,
    selected: true
  },
  {
    name: 'Eritrea',
    zhCommonName: '厄立特里亚',
    enCommonName: 'Eritrea',
    logo: ER,
    value: 1,
    selected: true
  },
  {
    name: 'Estonia',
    zhCommonName: '爱沙尼亚',
    enCommonName: 'Estonia',
    logo: EE,
    value: 1,
    selected: true
  },
  {
    name: 'Eswatini',
    zhCommonName: '斯威士兰',
    enCommonName: 'Eswatini',
    logo: SZ,
    value: 1,
    selected: true
  },
  {
    name: 'Ethiopia',
    zhCommonName: '埃塞俄比亚',
    enCommonName: 'Ethiopia',
    logo: ET,
    value: 1,
    selected: true
  },
  {
    name: 'Fiji',
    zhCommonName: '斐济',
    enCommonName: 'Fiji',
    logo: FJ,
    value: 1,
    selected: true
  },
  {
    name: 'Finland',
    zhCommonName: '芬兰',
    enCommonName: 'Finland',
    logo: FI,
    value: 1,
    selected: true
  },
  {
    name: 'France',
    zhCommonName: '法国',
    enCommonName: 'France',
    logo: FR,
    value: 1,
    selected: true
  },
  {
    name: 'Gabon',
    zhCommonName: '加蓬',
    enCommonName: 'Gabon',
    logo: GA,
    value: 1,
    selected: true
  },
  {
    name: 'Gambia',
    zhCommonName: '冈比亚',
    enCommonName: 'Gambia',
    logo: GM,
    value: 1,
    selected: true
  },
  {
    name: 'Georgia',
    zhCommonName: '格鲁吉亚',
    enCommonName: 'Georgia',
    logo: GE,
    value: 1,
    selected: true
  },
  {
    name: 'Germany',
    zhCommonName: '德国',
    enCommonName: 'Germany',
    logo: DE,
    value: 1,
    selected: true
  },
  {
    name: 'Ghana',
    zhCommonName: '加纳',
    enCommonName: 'Ghana',
    logo: GH,
    value: 1,
    selected: true
  },
  {
    name: 'Gibraltar',
    zhCommonName: '直布罗陀',
    enCommonName: 'Gibraltar',
    logo: GI,
    value: 1,
    selected: true
  },
  {
    name: 'Greece',
    zhCommonName: '希腊',
    enCommonName: 'Greece',
    logo: GR,
    value: 1,
    selected: true
  },
  {
    name: 'Greenland',
    zhCommonName: '格陵兰',
    enCommonName: 'Greenland',
    logo: GL,
    value: 1,
    selected: true
  },
  {
    name: 'Grenada',
    zhCommonName: '格林纳达',
    enCommonName: 'Grenada',
    logo: GD,
    value: 1,
    selected: true
  },
  {
    name: 'Guadeloupe',
    zhCommonName: '瓜德罗普',
    enCommonName: 'Guadeloupe',
    logo: GP,
    value: 1,
    selected: true
  },
  {
    name: 'Guam',
    zhCommonName: '关岛',
    enCommonName: 'Guam',
    logo: GU,
    value: 1,
    selected: true
  },
  {
    name: 'Guatemala',
    zhCommonName: '危地马拉',
    enCommonName: 'Guatemala',
    logo: GT,
    value: 1,
    selected: true
  },
  {
    name: 'Guernsey',
    zhCommonName: '根西',
    enCommonName: 'Guernsey',
    logo: GG,
    value: 1,
    selected: true
  },
  {
    name: 'Guinea',
    zhCommonName: '几内亚',
    enCommonName: 'Guinea',
    logo: GN,
    value: 1,
    selected: true
  },
  {
    name: 'Guinea-Bissau',
    zhCommonName: '几内亚比绍',
    enCommonName: 'Guinea-Bissau',
    logo: GW,
    value: 1,
    selected: true
  },
  {
    name: 'Guyana',
    zhCommonName: '圭亚那',
    enCommonName: 'Guyana',
    logo: GY,
    value: 1,
    selected: true
  },
  {
    name: 'Haiti',
    zhCommonName: '海地',
    enCommonName: 'Haiti',
    logo: HT,
    value: 1,
    selected: true
  },
  {
    name: 'Holy See',
    zhCommonName: '梵蒂冈',
    enCommonName: 'Holy See',
    logo: VA,
    value: 1,
    selected: true
  },
  {
    name: 'Honduras',
    zhCommonName: '洪都拉斯',
    enCommonName: 'Honduras',
    logo: HN,
    value: 1,
    selected: true
  },
  {
    name: 'Hong Kong',
    zhCommonName: '香港',
    enCommonName: 'Hong Kong',
    logo: HK,
    value: 1,
    selected: true
  },
  {
    name: 'Hungary',
    zhCommonName: '匈牙利',
    enCommonName: 'Hungary',
    logo: HU,
    value: 1,
    selected: true
  },
  {
    name: 'Iceland',
    zhCommonName: '冰岛',
    enCommonName: 'Iceland',
    logo: IS,
    value: 1,
    selected: true
  },
  {
    name: 'India',
    zhCommonName: '印度',
    enCommonName: 'India',
    logo: IN,
    value: 1,
    selected: true
  },
  {
    name: 'Indonesia',
    zhCommonName: '印尼',
    enCommonName: 'Indonesia',
    logo: ID,
    value: 1,
    selected: true
  },
  {
    name: 'Ireland',
    zhCommonName: '爱尔兰',
    enCommonName: 'Ireland',
    logo: IE,
    value: 1,
    selected: true
  },
  {
    name: 'Isle of Man',
    zhCommonName: '马恩岛',
    enCommonName: 'Isle of Man',
    logo: IM,
    value: 1,
    selected: true
  },
  {
    name: 'Israel',
    zhCommonName: '以色列',
    enCommonName: 'Israel',
    logo: IL,
    value: 1,
    selected: true
  },
  {
    name: 'Italy',
    zhCommonName: '意大利',
    enCommonName: 'Italy',
    logo: IT,
    value: 1,
    selected: true
  },
  {
    name: 'Jamaica',
    zhCommonName: '牙买加',
    enCommonName: 'Jamaica',
    logo: JM,
    value: 1,
    selected: true
  },
  {
    name: 'Japan',
    zhCommonName: '日本',
    enCommonName: 'Japan',
    logo: JP,
    value: 1,
    selected: true
  },
  {
    name: 'Jersey',
    zhCommonName: '泽西',
    enCommonName: 'Jersey',
    logo: JE,
    value: 1,
    selected: true
  },
  {
    name: 'Jordan',
    zhCommonName: '约旦',
    enCommonName: 'Jordan',
    logo: JO,
    value: 1,
    selected: true
  },
  {
    name: 'Kazakhstan',
    zhCommonName: '哈萨克斯坦',
    enCommonName: 'Kazakhstan',
    logo: KZ,
    value: 1,
    selected: true
  },
  {
    name: 'Kenya',
    zhCommonName: '肯尼亚',
    enCommonName: 'Kenya',
    logo: KE,
    value: 1,
    selected: true
  },
  {
    name: 'Kiribati',
    zhCommonName: '基里巴斯',
    enCommonName: 'Kiribati',
    logo: KI,
    value: 1,
    selected: true
  },
  {
    name: 'Korea',
    zhCommonName: '韩国',
    enCommonName: 'Korea',
    logo: KR,
    value: 1,
    selected: true
  },
  {
    name: 'Kuwait',
    zhCommonName: '科威特',
    enCommonName: 'Kuwait',
    logo: KW,
    value: 1,
    selected: true
  },
  {
    name: 'Kyrgyzstan',
    zhCommonName: '吉尔吉斯斯坦',
    enCommonName: 'Kyrgyzstan',
    logo: KG,
    value: 1,
    selected: true
  },
  {
    name: "Lao People's Democratic Republic",
    zhCommonName: '老挝',
    enCommonName: "Lao People's Democratic Republic",
    logo: LA,
    value: 1,
    selected: true
  },
  {
    name: 'Latvia',
    zhCommonName: '拉脱维亚',
    enCommonName: 'Latvia',
    logo: LV,
    value: 1,
    selected: true
  },
  {
    name: 'Lesotho',
    zhCommonName: '莱索托',
    enCommonName: 'Lesotho',
    logo: LS,
    value: 1,
    selected: true
  },
  {
    name: 'Liberia',
    zhCommonName: '利比里亚',
    enCommonName: 'Liberia',
    logo: LR,
    value: 1,
    selected: true
  },
  {
    name: 'Liechtenstein',
    zhCommonName: '列支敦士登',
    enCommonName: 'Liechtenstein',
    logo: LI,
    value: 1,
    selected: true
  },
  {
    name: 'Lithuania',
    zhCommonName: '立陶宛',
    enCommonName: 'Lithuania',
    logo: LT,
    value: 1,
    selected: true
  },
  {
    name: 'Luxembourg',
    zhCommonName: '卢森堡',
    enCommonName: 'Luxembourg',
    logo: LU,
    value: 1,
    selected: true
  },
  {
    name: 'Macao',
    zhCommonName: '澳门',
    enCommonName: 'Macao',
    logo: MO,
    value: 1,
    selected: true
  },
  {
    name: 'Madagascar',
    zhCommonName: '马达加斯加',
    enCommonName: 'Madagascar',
    logo: MG,
    value: 1,
    selected: true
  },
  {
    name: 'Malawi',
    zhCommonName: '马拉维',
    enCommonName: 'Malawi',
    logo: MW,
    value: 1,
    selected: true
  },
  {
    name: 'Malaysia',
    zhCommonName: '马来西亚',
    enCommonName: 'Malaysia',
    logo: MY,
    value: 1,
    selected: true
  },
  {
    name: 'Maldives',
    zhCommonName: '马尔代夫',
    enCommonName: 'Maldives',
    logo: MV,
    value: 1,
    selected: true
  },
  {
    name: 'Mali',
    zhCommonName: '马里',
    enCommonName: 'Mali',
    logo: ML,
    value: 1,
    selected: true
  },
  {
    name: 'Malta',
    zhCommonName: '马耳他',
    enCommonName: 'Malta',
    logo: MT,
    value: 1,
    selected: true
  },
  {
    name: 'Martinique',
    zhCommonName: '马提尼克',
    enCommonName: 'Martinique',
    logo: MQ,
    value: 1,
    selected: true
  },
  {
    name: 'Mauritania',
    zhCommonName: '毛里塔尼亚',
    enCommonName: 'Mauritania',
    logo: MR,
    value: 1,
    selected: true
  },
  {
    name: 'Mauritius',
    zhCommonName: '毛里求斯',
    enCommonName: 'Mauritius',
    logo: MU,
    value: 1,
    selected: true
  },
  {
    name: 'Mayotte',
    zhCommonName: '马约特',
    enCommonName: 'Mayotte',
    logo: YT,
    value: 1,
    selected: true
  },
  {
    name: 'Mexico',
    zhCommonName: '墨西哥',
    enCommonName: 'Mexico',
    logo: MX,
    value: 1,
    selected: true
  },
  {
    name: 'Monaco',
    zhCommonName: '摩纳哥',
    enCommonName: 'Monaco',
    logo: MC,
    value: 1,
    selected: true
  },
  {
    name: 'Mongolia',
    zhCommonName: '蒙古',
    enCommonName: 'Mongolia',
    logo: MN,
    value: 1,
    selected: true
  },
  {
    name: 'Montenegro',
    zhCommonName: '黑山',
    enCommonName: 'Montenegro',
    logo: ME,
    value: 1,
    selected: true
  },
  {
    name: 'Montserrat',
    zhCommonName: '蒙特塞拉特',
    enCommonName: 'Montserrat',
    logo: MS,
    value: 1,
    selected: true
  },
  {
    name: 'Morocco',
    zhCommonName: '摩洛哥',
    enCommonName: 'Morocco',
    logo: MA,
    value: 1,
    selected: true
  },
  {
    name: 'Mozambique',
    zhCommonName: '莫桑比克',
    enCommonName: 'Mozambique',
    logo: MZ,
    value: 1,
    selected: true
  },
  {
    name: 'Namibia',
    zhCommonName: '纳米比亚',
    enCommonName: 'Namibia',
    logo: NA,
    value: 1,
    selected: true
  },
  {
    name: 'Nauru',
    zhCommonName: '瑙鲁',
    enCommonName: 'Nauru',
    logo: NR,
    value: 1,
    selected: true
  },
  {
    name: 'Nepal',
    zhCommonName: '尼泊尔',
    enCommonName: 'Nepal',
    logo: NP,
    value: 1,
    selected: true
  },
  {
    name: 'Netherlands',
    zhCommonName: '荷兰',
    enCommonName: 'Netherlands',
    logo: NL,
    value: 1,
    selected: true
  },
  {
    name: 'New Caledonia',
    zhCommonName: '新喀里多尼亚',
    enCommonName: 'New Caledonia',
    logo: NC,
    value: 1,
    selected: true
  },
  {
    name: 'New Zealand',
    zhCommonName: '新西兰',
    enCommonName: 'New Zealand',
    logo: NZ,
    value: 1,
    selected: true
  },
  {
    name: 'Niger',
    zhCommonName: '尼日尔',
    enCommonName: 'Niger',
    logo: NE,
    value: 1,
    selected: true
  },
  {
    name: 'Nigeria',
    zhCommonName: '尼日利亚',
    enCommonName: 'Nigeria',
    logo: NG,
    value: 1,
    selected: true
  },
  {
    name: 'Niue',
    zhCommonName: '纽埃',
    enCommonName: 'Niue',
    logo: NU,
    value: 1,
    selected: true
  },
  {
    name: 'North Macedonia',
    zhCommonName: '北马其顿',
    enCommonName: 'North Macedonia',
    logo: MK,
    value: 1,
    selected: true
  },
  {
    name: 'Norway',
    zhCommonName: '挪威',
    enCommonName: 'Norway',
    logo: NO,
    value: 1,
    selected: true
  },
  {
    name: 'Oman',
    zhCommonName: '阿曼',
    enCommonName: 'Oman',
    logo: OM,
    value: 1,
    selected: true
  },
  {
    name: 'Palau',
    zhCommonName: '帕劳',
    enCommonName: 'Palau',
    logo: PW,
    value: 1,
    selected: true
  },
  {
    name: 'Panama',
    zhCommonName: '巴拿马',
    enCommonName: 'Panama',
    logo: PA,
    value: 1,
    selected: true
  },
  {
    name: 'Papua New Guinea',
    zhCommonName: '巴布亚新几内亚',
    enCommonName: 'Papua New Guinea',
    logo: PG,
    value: 1,
    selected: true
  },
  {
    name: 'Paraguay',
    zhCommonName: '巴拉圭',
    enCommonName: 'Paraguay',
    logo: PY,
    value: 1,
    selected: true
  },
  {
    name: 'Peru',
    zhCommonName: '秘鲁',
    enCommonName: 'Peru',
    logo: PE,
    value: 1,
    selected: true
  },
  {
    name: 'Philippines',
    zhCommonName: '菲律宾',
    enCommonName: 'Philippines',
    logo: PH,
    value: 1,
    selected: true
  },
  {
    name: 'Pitcairn',
    zhCommonName: '皮特凯恩群岛',
    enCommonName: 'Pitcairn',
    logo: PN,
    value: 1,
    selected: true
  },
  {
    name: 'Poland',
    zhCommonName: '波兰',
    enCommonName: 'Poland',
    logo: PL,
    value: 1,
    selected: true
  },
  {
    name: 'Portugal',
    zhCommonName: '葡萄牙',
    enCommonName: 'Portugal',
    logo: PT,
    value: 1,
    selected: true
  },
  {
    name: 'Puerto Rico',
    zhCommonName: '波多黎各',
    enCommonName: 'Puerto Rico',
    logo: PR,
    value: 1,
    selected: true
  },
  {
    name: 'Qatar',
    zhCommonName: '卡塔尔',
    enCommonName: 'Qatar',
    logo: QA,
    value: 1,
    selected: true
  },
  {
    name: 'Republic of Moldova',
    zhCommonName: '摩尔多瓦',
    enCommonName: 'Republic of Moldova',
    logo: MD,
    value: 1,
    selected: true
  },
  {
    name: 'Romania',
    zhCommonName: '罗马尼亚',
    enCommonName: 'Romania',
    logo: RO,
    value: 1,
    selected: true
  },
  {
    name: 'Rwanda',
    zhCommonName: '卢旺达',
    enCommonName: 'Rwanda',
    logo: RW,
    value: 1,
    selected: true
  },
  {
    name: 'Réunion',
    zhCommonName: '留尼汪',
    enCommonName: 'Réunion',
    logo: RE,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Barthélemy',
    zhCommonName: '圣巴泰勒米',
    enCommonName: 'Saint Barthélemy',
    logo: BL,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Kitts and Nevis',
    zhCommonName: '圣基茨和尼维斯',
    enCommonName: 'Saint Kitts and Nevis',
    logo: KN,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Lucia',
    zhCommonName: '圣卢西亚',
    enCommonName: 'Saint Lucia',
    logo: LC,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Martin (French part)',
    zhCommonName: '法属圣马丁',
    enCommonName: 'Saint Martin (French part)',
    logo: MF,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Pierre and Miquelon',
    zhCommonName: '圣皮埃尔和密克隆',
    enCommonName: 'Saint Pierre and Miquelon',
    logo: PM,
    value: 1,
    selected: true
  },
  {
    name: 'Saint Vincent and the Grenadines',
    zhCommonName: '圣文森特和格林纳丁斯',
    enCommonName: 'Saint Vincent and the Grenadines',
    logo: VC,
    value: 1,
    selected: true
  },
  {
    name: 'Samoa',
    zhCommonName: '萨摩亚',
    enCommonName: 'Samoa',
    logo: WS,
    value: 1,
    selected: true
  },
  {
    name: 'San Marino',
    zhCommonName: '圣马力诺',
    enCommonName: 'San Marino',
    logo: SM,
    value: 1,
    selected: true
  },
  {
    name: 'Sao Tome and Principe',
    zhCommonName: '圣多美和普林西比',
    enCommonName: 'Sao Tome and Principe',
    logo: ST,
    value: 1,
    selected: true
  },
  {
    name: 'Saudi Arabia',
    zhCommonName: '沙特阿拉伯',
    enCommonName: 'Saudi Arabia',
    logo: SA,
    value: 1,
    selected: true
  },
  {
    name: 'Senegal',
    zhCommonName: '塞内加尔',
    enCommonName: 'Senegal',
    logo: SN,
    value: 1,
    selected: true
  },
  {
    name: 'Serbia',
    zhCommonName: '塞尔维亚',
    enCommonName: 'Serbia',
    logo: RS,
    value: 1,
    selected: true
  },
  {
    name: 'Seychelles',
    zhCommonName: '塞舌尔',
    enCommonName: 'Seychelles',
    logo: SC,
    value: 1,
    selected: true
  },
  {
    name: 'Sierra Leone',
    zhCommonName: '塞拉利昂',
    enCommonName: 'Sierra Leone',
    logo: SL,
    value: 1,
    selected: true
  },
  {
    name: 'Singapore',
    zhCommonName: '新加坡',
    enCommonName: 'Singapore',
    logo: SG,
    value: 1,
    selected: true
  },
  {
    name: 'Slovakia',
    zhCommonName: '斯洛伐克',
    enCommonName: 'Slovakia',
    logo: SK,
    value: 1,
    selected: true
  },
  {
    name: 'Slovenia',
    zhCommonName: '斯洛文尼亚',
    enCommonName: 'Slovenia',
    logo: SI,
    value: 1,
    selected: true
  },
  {
    name: 'South Africa',
    zhCommonName: '南非',
    enCommonName: 'South Africa',
    logo: ZA,
    value: 1,
    selected: true
  },
  {
    name: 'Spain',
    zhCommonName: '西班牙',
    enCommonName: 'Spain',
    logo: ES,
    value: 1,
    selected: true
  },
  {
    name: 'Sri Lanka',
    zhCommonName: '斯里兰卡',
    enCommonName: 'Sri Lanka',
    logo: LK,
    value: 1,
    selected: true
  },
  {
    name: 'Suriname',
    zhCommonName: '苏里南',
    enCommonName: 'Suriname',
    logo: SR,
    value: 1,
    selected: true
  },
  {
    name: 'Svalbard and Jan Mayen',
    zhCommonName: '斯瓦尔巴和扬马延',
    enCommonName: 'Svalbard and Jan Mayen',
    logo: SJ,
    value: 1,
    selected: true
  },
  {
    name: 'Sweden',
    zhCommonName: '瑞典',
    enCommonName: 'Sweden',
    logo: SE,
    value: 1,
    selected: true
  },
  {
    name: 'Switzerland',
    zhCommonName: '瑞士',
    enCommonName: 'Switzerland',
    logo: CH,
    value: 1,
    selected: true
  },
  {
    name: 'Syrian Arab Republic',
    zhCommonName: '叙利亚',
    enCommonName: 'Syrian Arab Republic',
    logo: SY,
    value: 1,
    selected: true
  },
  {
    name: 'Taiwan, Province of China',
    zhCommonName: '台湾',
    enCommonName: 'Taiwan, Province of China',
    logo: TW,
    value: 1,
    selected: true
  },
  {
    name: 'Tajikistan',
    zhCommonName: '塔吉克斯坦',
    enCommonName: 'Tajikistan',
    logo: TJ,
    value: 1,
    selected: true
  },
  {
    name: 'Thailand',
    zhCommonName: '泰国',
    enCommonName: 'Thailand',
    logo: TH,
    value: 1,
    selected: true
  },
  {
    name: 'Timor-Leste',
    zhCommonName: '东帝汶',
    enCommonName: 'Timor-Leste',
    logo: TL,
    value: 1,
    selected: true
  },
  {
    name: 'Togo',
    zhCommonName: '多哥',
    enCommonName: 'Togo',
    logo: TG,
    value: 1,
    selected: true
  },
  {
    name: 'Tokelau',
    zhCommonName: '托克劳',
    enCommonName: 'Tokelau',
    logo: TK,
    value: 1,
    selected: true
  },
  {
    name: 'Tonga',
    zhCommonName: '汤加',
    enCommonName: 'Tonga',
    logo: TO,
    value: 1,
    selected: true
  },
  {
    name: 'Trinidad and Tobago',
    zhCommonName: '特立尼达和多巴哥',
    enCommonName: 'Trinidad and Tobago',
    logo: TT,
    value: 1,
    selected: true
  },
  {
    name: 'Tunisia',
    zhCommonName: '突尼斯',
    enCommonName: 'Tunisia',
    logo: TN,
    value: 1,
    selected: true
  },
  {
    name: 'Turkey',
    zhCommonName: '土耳其',
    enCommonName: 'Turkey',
    logo: TR,
    value: 1,
    selected: true
  },
  {
    name: 'Turkmenistan',
    zhCommonName: '土库曼斯坦',
    enCommonName: 'Turkmenistan',
    logo: TM,
    value: 1,
    selected: true
  },
  {
    name: 'Turks and Caicos Islands',
    zhCommonName: '特克斯和凯科斯群岛',
    enCommonName: 'Turks and Caicos Islands',
    logo: TC,
    value: 1,
    selected: true
  },
  {
    name: 'Tuvalu',
    zhCommonName: '图瓦卢',
    enCommonName: 'Tuvalu',
    logo: TV,
    value: 1,
    selected: true
  },
  {
    name: 'Uganda',
    zhCommonName: '乌干达',
    enCommonName: 'Uganda',
    logo: UG,
    value: 1,
    selected: true
  },
  {
    name: 'Ukraine',
    zhCommonName: '乌克兰',
    enCommonName: 'Ukraine',
    logo: UA,
    value: 1,
    selected: true
  },
  {
    name: 'United Arab Emirates',
    zhCommonName: '阿联酋',
    enCommonName: 'United Arab Emirates',
    logo: AE,
    value: 1,
    selected: true
  },
  {
    name: 'United Kingdom',
    zhCommonName: '英国',
    enCommonName: 'United Kingdom',
    logo: GB,
    value: 1,
    selected: true
  },
  {
    name: 'United Republic of Tanzania',
    zhCommonName: '坦桑尼亚',
    enCommonName: 'United Republic of Tanzania',
    logo: TZ,
    value: 1,
    selected: true
  },
  {
    name: 'United States',
    zhCommonName: '美国',
    enCommonName: 'United States',
    logo: US,
    value: 1,
    selected: true
  },
  {
    name: 'Uruguay',
    zhCommonName: '乌拉圭',
    enCommonName: 'Uruguay',
    logo: UY,
    value: 1,
    selected: true
  },
  {
    name: 'Uzbekistan',
    zhCommonName: '乌兹别克斯坦',
    enCommonName: 'Uzbekistan',
    logo: UZ,
    value: 1,
    selected: true
  },
  {
    name: 'Vanuatu',
    zhCommonName: '瓦努阿图',
    enCommonName: 'Vanuatu',
    logo: VU,
    value: 1,
    selected: true
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    zhCommonName: '委内瑞拉',
    enCommonName: 'Venezuela (Bolivarian Republic of)',
    logo: VE,
    value: 1,
    selected: true
  },
  {
    name: 'Vietnam',
    zhCommonName: '越南',
    enCommonName: 'Vietnam',
    logo: VN,
    value: 1,
    selected: true
  },
  {
    name: 'Virgin Islands (U.S.)',
    zhCommonName: '美属维尔京群岛',
    enCommonName: 'Virgin Islands (U.S.)',
    logo: VI,
    value: 1,
    selected: true
  },
  {
    name: 'Wallis and Futuna',
    zhCommonName: '瓦利斯和富图纳',
    enCommonName: 'Wallis and Futuna',
    logo: WF,
    value: 1,
    selected: true
  },
  {
    name: 'Western Sahara',
    zhCommonName: '西撒哈拉',
    enCommonName: 'Western Sahara',
    logo: EH,
    value: 1,
    selected: true
  },
  {
    name: 'Zambia',
    zhCommonName: '赞比亚',
    enCommonName: 'Zambia',
    logo: ZM,
    value: 1,
    selected: true
  },
  {
    name: 'Åland Islands',
    zhCommonName: '奥兰',
    enCommonName: 'Åland Islands',
    logo: AX,
    value: 1,
    selected: true
  }
]
