<template>
  <div class="app-dark-bg">
    <div id="card-solution" class="card-solution g-container ">
      <h2 class="g-title">{{ $t('risk.title') }}</h2>

      <div v-if="cards.length>=4" class="cards">
        <img ref="img" class="img--card" src="@/assets/images/home/card/card1.jpeg" alt="">
        <CardTypeItem :title="cards[0].title" :content="cards[0].content" :list="cards[0].list" />
        <CardTypeItem :title="cards[1].title" :content="cards[1].content" :list="cards[1].list" :union="false" />
        <img class="img--card" src="@/assets/images/home/card/card2.jpeg" alt="">
        <img class="img--card" src="@/assets/images/home/card/card3.jpeg" alt="">
        <CardTypeItem :title="cards[2].title" :content="cards[2].content" :list="cards[2].list" :union="false" />

        <CardTypeItem :title="cards[3].title" :content="cards[3].content" :list="cards[2].list" :union="false" />
        <img class="img--card" src="@/assets/images/home/card/card4.jpeg" alt="">
      </div>
      <RouterLink class="g-link-clear btn--more" to="/">
        {{ $t('risk.btn') }}
      </RouterLink>
    </div>
  </div>
</template>

<script>

import CardTypeItem from './CardTypeItem.vue'

export default {
  name: 'CardSolution',
  components: { CardTypeItem },
  data() {
    return {
      cards: []
    }
  },
  watch: {
    '$i18n.locale': function() {
      this.updateSwiper()
    }
  },
  mounted() {
    this.updateSwiper()
  },
  methods: {
    updateSwiper() {
      this.cards = [
        {
          title: this.$t('risk.one.title'),
          content: this.$t('risk.one.desc'),
          list: [
          ]
        },
        {
          title: this.$t('risk.two.title'),
          content: this.$t('risk.two.desc'),
          list: [
          ]
        },
        {
          title: this.$t('risk.three.title'),
          content: this.$t('risk.three.desc'),
          list: [
          ]
        },
        {
          title: this.$t('risk.four.title'),
          content: this.$t('risk.four.desc'),
          list: [
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/var.scss';

.card-solution {
  padding-top: 160px;
  padding-bottom: 200px;
  text-align: center;
  margin: 0 auto;
}

.tip1 {
  margin-top: 20px;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-content);
}

.tip2 {
  margin: 60px auto 0;
  padding: 20px 64px;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  background: var(--background-theme);
  color: var(--color-theme);
}

.img--card {
  width: 100%;
  max-width: 540px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 100px;
  grid-row-gap: 120px;
  grid-column-gap: 100px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 0;

    .img--card {
      &:not(:first-child) {
        margin-top: 100px;
      }
    }

    &>div {
      margin-top: 80px;
      max-width: 540px;
    }

    &>* {
      &:nth-child(3) {
        grid-row: 4;
      }

      &:nth-child(4) {
        grid-row: 3;
      }
    }
  }
}

.btn--more {
  margin-top: 120px;
  display: inline-block;
  background: var(--color-theme);

  border-radius: 30px;
  line-height: 60px;
  padding: 0 70px;
  color: #FFF;
  font-size: 18px;
  cursor: pointer;
  transition: opacity .3s;
  opacity: .8;

  &:hover {
    opacity: 1;
  }
}
</style>
