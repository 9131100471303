<template>
  <div class="card-type-item">
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
    <!-- <img class="img--icon" src="@/assets/images/home/bank1.png" alt="">
    <img class="img--icon" src="@/assets/images/home/bank2.png" alt=""> -->
    <!-- <img class="img--icon" :class="{'disable':!union}" src="@/assets/images/home/bank3.png" alt=""> -->
    <div v-for="(item, index) in list" :key="index" class="item__wrapper">
      <img class="dec" src="@/assets/images/home/check.png" alt="check">
      <span class="item">{{ item }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardTypeItem',
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    content: {
      type: String,
      require: true,
      default: ''
    },
    list: {
      type: Array,
      require: true,
      default: () => []
    },
    union: {
      type: Boolean,
      require: false,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-type-item {
    text-align: left;
  }

  .title {
    font-size: 22px;
    line-height: 28px;
  }

  .content {
    margin-top: 30px;
    margin-bottom: 60px;
    font-size: 16px;
    line-height: 28px;
    color: var(--color-content);
  }

  .img--icon {
    & + & {
      margin-left: 32px;
    }

    &.disable {
      opacity: .1;
    }
  }

  .dec {
    height: 12px;
  }

  .item {
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-content);
  }

  .item__wrapper {
    margin-top: 40px;
    & + & {
      margin-top: 10px;
    }
  }
</style>
