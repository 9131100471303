import { flags } from './selectedFlag'

export const worldData = [

  {
    name: 'Afghanistan',
    value: 0,
    selected: true
  },
  {
    name: 'Angola',
    value: 0,
    selected: true
  },
  {
    name: 'Albania',
    value: 0,
    selected: true
  },
  {
    name: 'United Arab Emirates',
    value: 0,
    selected: true
  },
  {
    name: 'Argentina',
    value: 0,
    selected: true
  },
  {
    name: 'Armenia',
    value: 0,
    selected: true
  },
  {
    name: 'French Southern and Antarctic Lands',
    value: 0,
    selected: true
  },
  {
    name: 'Australia',
    value: 0,
    selected: true
  },
  {
    name: 'Austria',
    value: 0,
    selected: true
  },
  {
    name: 'Azerbaijan',
    value: 0,
    selected: true
  },
  {
    name: 'Burundi',
    value: 0,
    selected: true
  },
  {
    name: 'Belgium',
    value: 0,
    selected: true
  },
  {
    name: 'Benin',
    value: 0,
    selected: true
  },
  {
    name: 'Burkina Faso',
    value: 0,
    selected: true
  },
  {
    name: 'Bangladesh',
    value: 0,
    selected: true
  },
  {
    name: 'Bulgaria',
    value: 0,
    selected: true
  },
  {
    name: 'The Bahamas',
    value: 0,
    selected: true
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 0,
    selected: true
  },
  {
    name: 'Belarus',
    value: 0,
    selected: true
  },
  {
    name: 'Belize',
    value: 0,
    selected: true
  },
  {
    name: 'Bermuda',
    value: 0,
    selected: true
  },
  {
    name: 'Bolivia',
    value: 0,
    selected: true
  },
  {
    name: 'Brazil',
    value: 0,
    selected: true
  },
  {
    name: 'Brunei',
    value: 0,
    selected: true
  },
  {
    name: 'Bhutan',
    value: 0,
    selected: true
  },
  {
    name: 'Botswana',
    value: 0,
    selected: true
  },
  {
    name: 'Central African Republic',
    value: 0,
    selected: true
  },
  {
    name: 'Canada',
    value: 0,
    selected: true
  },
  {
    name: 'Switzerland',
    value: 0,
    selected: true
  },
  {
    name: 'Chile',
    value: 0,
    selected: true
  },
  {
    name: 'China',
    value: 0,
    selected: true
  },
  {
    name: 'Ivory Coast',
    value: 0,
    selected: true
  },
  {
    name: 'Cameroon',
    value: 0,
    selected: true
  },
  {
    name: 'Democratic Republic of the Congo',
    value: 0,
    selected: true
  },
  {
    name: 'Republic of the Congo',
    value: 0,
    selected: true
  },
  {
    name: 'Colombia',
    value: 0,
    selected: true
  },
  {
    name: 'Costa Rica',
    value: 0,
    selected: true
  },
  {
    name: 'Cuba',
    value: 0,
    selected: true
  },
  {
    name: 'Northern Cyprus',
    value: 0,
    selected: true
  },
  {
    name: 'Cyprus',
    value: 0,
    selected: true
  },
  {
    name: 'Czech Republic',
    value: 0,
    selected: true
  },
  {
    name: 'Germany',
    value: 0,
    selected: true
  },
  {
    name: 'Djibouti',
    value: 0,
    selected: true
  },
  {
    name: 'Denmark',
    value: 0,
    selected: true
  },
  {
    name: 'Dominican Republic',
    value: 0,
    selected: true
  },
  {
    name: 'Algeria',
    value: 0,
    selected: true
  },
  {
    name: 'Ecuador',
    value: 0,
    selected: true
  },
  {
    name: 'Egypt',
    value: 0,
    selected: true
  },
  {
    name: 'Eritrea',
    value: 0,
    selected: true
  },
  {
    name: 'Spain',
    value: 0,
    selected: true
  },
  {
    name: 'Estonia',
    value: 0,
    selected: true
  },
  {
    name: 'Ethiopia',
    value: 0,
    selected: true
  },
  {
    name: 'Finland',
    value: 0,
    selected: true
  },
  {
    name: 'Fiji',
    value: 0,
    selected: true
  },
  {
    name: 'Falkland Islands',
    value: 0,
    selected: true
  },
  {
    name: 'France',
    value: 0,
    selected: true
  },
  {
    name: 'Gabon',
    value: 0,
    selected: true
  },
  {
    name: 'United Kingdom',
    value: 0,
    selected: true
  },
  {
    name: 'Georgia',
    value: 0,
    selected: true
  },
  {
    name: 'Ghana',
    value: 0,
    selected: true
  },
  {
    name: 'Guinea',
    value: 0,
    selected: true
  },
  {
    name: 'Gambia',
    value: 0,
    selected: true
  },
  {
    name: 'Guinea Bissau',
    value: 0,
    selected: true
  },
  {
    name: 'Equatorial Guinea',
    value: 0,
    selected: true
  },
  {
    name: 'Greece',
    value: 0,
    selected: true
  },
  {
    name: 'Greenland',
    value: 0,
    selected: true
  },
  {
    name: 'Guatemala',
    value: 0,
    selected: true
  },
  {
    name: 'French Guiana',
    value: 0,
    selected: true
  },
  {
    name: 'Guyana',
    value: 0,
    selected: true
  },
  {
    name: 'Honduras',
    value: 0,
    selected: true
  },
  {
    name: 'Croatia',
    value: 0,
    selected: true
  },
  {
    name: 'Haiti',
    value: 0,
    selected: true
  },
  {
    name: 'Hungary',
    value: 0,
    selected: true
  },
  {
    name: 'Indonesia',
    value: 0,
    selected: true
  },
  {
    name: 'India',
    value: 0,
    selected: true
  },
  {
    name: 'Ireland',
    value: 0,
    selected: true
  },
  {
    name: 'Iran',
    value: 0,
    selected: true
  },
  {
    name: 'Iraq',
    value: 0,
    selected: true
  },
  {
    name: 'Iceland',
    value: 0,
    selected: true
  },
  {
    name: 'Israel',
    value: 0,
    selected: true
  },
  {
    name: 'Italy',
    value: 0,
    selected: true
  },
  {
    name: 'Jamaica',
    value: 0,
    selected: true
  },
  {
    name: 'Jordan',
    value: 0,
    selected: true
  },
  {
    name: 'Japan',
    value: 0,
    selected: true
  },
  {
    name: 'Kazakhstan',
    value: 0,
    selected: true
  },
  {
    name: 'Kenya',
    value: 0,
    selected: true
  },
  {
    name: 'Kyrgyzstan',
    value: 0,
    selected: true
  },
  {
    name: 'Cambodia',
    value: 0,
    selected: true
  },
  {
    name: 'South Korea',
    value: 0,
    selected: true
  },
  {
    name: 'Kosovo',
    value: 0,
    selected: true
  },
  {
    name: 'Kuwait',
    value: 0,
    selected: true
  },
  {
    name: 'Laos',
    value: 0,
    selected: true
  },
  {
    name: 'Lebanon',
    value: 0,
    selected: true
  },
  {
    name: 'Liberia',
    value: 0,
    selected: true
  },
  {
    name: 'Libya',
    value: 0,
    selected: true
  },
  {
    name: 'Sri Lanka',
    value: 0,
    selected: true
  },
  {
    name: 'Lesotho',
    value: 0,
    selected: true
  },
  {
    name: 'Lithuania',
    value: 0,
    selected: true
  },
  {
    name: 'Luxembourg',
    value: 0,
    selected: true
  },
  {
    name: 'Latvia',
    value: 0,
    selected: true
  },
  {
    name: 'Morocco',
    value: 0,
    selected: true
  },
  {
    name: 'Moldova',
    value: 0,
    selected: true
  },
  {
    name: 'Madagascar',
    value: 0,
    selected: true
  },
  {
    name: 'Mexico',
    value: 0,
    selected: true
  },
  {
    name: 'Macedonia',
    value: 0,
    selected: true
  },
  {
    name: 'Mali',
    value: 0,
    selected: true
  },
  {
    name: 'Myanmar',
    value: 0,
    selected: true
  },
  {
    name: 'Montenegro',
    value: 0,
    selected: true
  },
  {
    name: 'Mongolia',
    value: 0,
    selected: true
  },
  {
    name: 'Mozambique',
    value: 0,
    selected: true
  },
  {
    name: 'Mauritania',
    value: 0,
    selected: true
  },
  {
    name: 'Malawi',
    value: 0,
    selected: true
  },
  {
    name: 'Malaysia',
    value: 0,
    selected: true
  },
  {
    name: 'Namibia',
    value: 0,
    selected: true
  },
  {
    name: 'New Caledonia',
    value: 0,
    selected: true
  },
  {
    name: 'Niger',
    value: 0,
    selected: true
  },
  {
    name: 'Nigeria',
    value: 0,
    selected: true
  },
  {
    name: 'Nicaragua',
    value: 0,
    selected: true
  },
  {
    name: 'Netherlands',
    value: 0,
    selected: true
  },
  {
    name: 'Norway',
    value: 0,
    selected: true
  },
  {
    name: 'Nepal',
    value: 0,
    selected: true
  },
  {
    name: 'New Zealand',
    value: 0,
    selected: true
  },
  {
    name: 'Oman',
    value: 0,
    selected: true
  },
  {
    name: 'Pakistan',
    value: 0,
    selected: true
  },
  {
    name: 'Panama',
    value: 0,
    selected: true
  },
  {
    name: 'Peru',
    value: 0,
    selected: true
  },
  {
    name: 'Philippines',
    value: 0,
    selected: true
  },
  {
    name: 'Papua New Guinea',
    value: 0,
    selected: true
  },
  {
    name: 'Poland',
    value: 0,
    selected: true
  },
  {
    name: 'Puerto Rico',
    value: 0,
    selected: true
  },
  {
    name: 'North Korea',
    value: 0,
    selected: true
  },
  {
    name: 'Portugal',
    value: 0,
    selected: true
  },
  {
    name: 'Paraguay',
    value: 0,
    selected: true
  },
  {
    name: 'Qatar',
    value: 0,
    selected: true
  },
  {
    name: 'Romania',
    value: 0,
    selected: true
  },
  {
    name: 'Russia',
    value: 0,
    selected: true
  },
  {
    name: 'Rwanda',
    value: 0,
    selected: true
  },
  {
    name: 'Western Sahara',
    value: 0,
    selected: true
  },
  {
    name: 'Saudi Arabia',
    value: 0,
    selected: true
  },
  {
    name: 'Sudan',
    value: 0,
    selected: true
  },
  {
    name: 'South Sudan',
    value: 0,
    selected: true
  },
  {
    name: 'Senegal',
    value: 0,
    selected: true
  },
  {
    name: 'Solomon Islands',
    value: 0,
    selected: true
  },
  {
    name: 'Sierra Leone',
    value: 0,
    selected: true
  },
  {
    name: 'El Salvador',
    value: 0,
    selected: true
  },
  {
    name: 'Somaliland',
    value: 0,
    selected: true
  },
  {
    name: 'Somalia',
    value: 0,
    selected: true
  },
  {
    name: 'Republic of Serbia',
    value: 0,
    selected: true
  },
  {
    name: 'Suriname',
    value: 0,
    selected: true
  },
  {
    name: 'Slovakia',
    value: 0,
    selected: true
  },
  {
    name: 'Slovenia',
    value: 0,
    selected: true
  },
  {
    name: 'Sweden',
    value: 0,
    selected: true
  },
  {
    name: 'Swaziland',
    value: 0,
    selected: true
  },
  {
    name: 'Syria',
    value: 0,
    selected: true
  },
  {
    name: 'Chad',
    value: 0,
    selected: true
  },
  {
    name: 'Togo',
    value: 0,
    selected: true
  },
  {
    name: 'Thailand',
    value: 0,
    selected: true
  },
  {
    name: 'Tajikistan',
    value: 0,
    selected: true
  },
  {
    name: 'Turkmenistan',
    value: 0,
    selected: true
  },
  {
    name: 'East Timor',
    value: 0,
    selected: true
  },
  {
    name: 'Trinidad and Tobago',
    value: 0,
    selected: true
  },
  {
    name: 'Tunisia',
    value: 0,
    selected: true
  },
  {
    name: 'Turkey',
    value: 0,
    selected: true
  },
  {
    name: 'United Republic of Tanzania',
    value: 0,
    selected: true
  },
  {
    name: 'Uganda',
    value: 0,
    selected: true
  },
  {
    name: 'Ukraine',
    value: 0,
    selected: true
  },
  {
    name: 'Uruguay',
    value: 0,
    selected: true
  },
  {
    name: 'United States of America',
    value: 0,
    selected: true
  },
  {
    name: 'Uzbekistan',
    value: 0,
    selected: true
  },
  {
    name: 'Venezuela',
    value: 0,
    selected: true
  },
  {
    name: 'Vietnam',
    value: 0,
    selected: true
  },
  {
    name: 'Vanuatu',
    value: 0,
    selected: true
  },
  {
    name: 'West Bank',
    value: 0,
    selected: true
  },
  {
    name: 'Yemen',
    value: 0,
    selected: true
  },
  {
    name: 'South Africa',
    value: 0,
    selected: true
  },
  {
    name: 'Zambia',
    value: 0,
    selected: true
  },
  {
    name: 'Zimbabwe',
    value: 0,
    selected: true
  }
]

export const selectedWorldData = [
  ...flags
]
export const name = 'WORLD'
